import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export default function PolicyClubRules() {
  return ( 
    <Layout>
        <section className="container">
            <h1>Rules of the Club <a href="downloads/LCJFC Policy Document 2017-18.pdf" target="_blank" title="download policy"><FontAwesomeIcon className="lcj-color" icon={faFileDownload}></FontAwesomeIcon></a></h1>
            <InnerPageTwitterLayout>
<div className="small">

<h5>1) Club Name</h5>
<p>The club shall be called Leeds City Juniors FC (LCJFC).</p>

<h5>2) Objects</h5>
<p>The objects of the club are to provide facilities for, and to promote community participation in, the amateur sport of Association Football in Adel LS16 and surrounding districts.</p>

<h5>3) Status of Rules</h5>
<p>These rules (the “Club Rules”) form a binding agreement between each member of LCJFC.</p>

<h5>4) Rules & Regulations</h5>
<p>
a) The members of the Club shall so exercise their rights, powers and duties and shall, where appropriate, use their best endeavours to ensure that others conduct themselves so that the business and affairs of the Club are carried out in accordance with the Rules and Regulation of The Football Association Limited (“The FA”), County Football Association to which the Club is affiliated (“Parent County Association”) and Competitions in which the Club participates, for the time being in force.<br/>
b) No alteration to the Club Rules shall be effective without written approval by the Parent County Association, The FA and the Parent County Association reserve the right to approve any proposed changes to the Club rules.<br/>
c) LCJFC will also abide by the FA’s Safeguarding Children Policies & Procedures, Codes of Conduct & the Equal Opportunities & Anti-Discrimination Policy as shall be in place from time to time.
</p>

<h5>5) Club Membership</h5>
<p>
a) The members of the club from time to time shall be those persons listed in the register of members (the “Membership Register”) which shall be maintained by the Registration Secretary and retained by the LCJFC committee.<br/>
b) Membership of the club shall be open to anyone interested in the sport on application regardless of sex, age, disability, ethnicity, nationality, sexual orientation, religion or other beliefs. However, limitation of membership according to available facilities is allowable on a non-discriminatory basis.<br/>
c) The Club may have different classes of membership and subscription on a non-discriminatory and fair basis.<br/>
d) The Club Committee may refuse membership only for good cause such as conduct or character likely to bring the sport or Club into disrepute. Appeal against refusal may be made to the members.<br/>
e) Any person who wishes to become a member must apply on the Membership Application Form (normally a player registration form) & deliver it to LCJFC. Election to membership shall be at the sole discretion of the Team Manager & LCJFC committee. Membership shall become effective upon an applicant’s name being entered in the Membership Register.<br/>
f) In the event of a member’s resignation or expulsion, his or her name shall be removed from the Membership Register.<br/>
g) The FA & parent CFA shall be given access to the Membership Register on demand.
</p>

<h5>6) Annual Membership Fee</h5>
<p>
a) An annual fee, payable by each member, shall be determined from time to time by the LCJFC committee and set at a level that will not pose a significant obstacle to community participation. Any fee shall be payable on a successful application for membership & annually by each member. Fees shall not be repayable unless agreed by the LCJFC committee.<br/>
b) All players will be required to complete a registration form for the purposes of league rules. In addition, each player is required to complete a “contact” form which details the individuals LCJFC should contact in the event of an emergency. All details are retained by the Secretary, LCJ Registration Officer &/or the LCJFC committee.<br/>
c) The LCJFC committee shall have the authority to levy further subscriptions from the members as are reasonably necessary to fulfill the objectives of the club.
</p>

<h5>7) Resignations & Expulsion</h5>
<p>
a) A member shall cease to be a member of LCJFC if, & from the date on which, he/she gives notice to the LCJFC committee of their resignation. A member whose annual membership fee or further subscription is more than two months in arrears shall be deemed to have resigned.<br/>
b) The Club Committee shall have the power to remove a member from membership only for good cause such as conduct or character likely to bring the Club or sport into disrepute. An appeal against such a decision may be made to the members.<br/>
c) A member who resigns or is expelled shall not be entitled to claim any, or a share of any, of the club property.
</p>

<h5>8) Club Committee</h5>
<p>
a) The LCJFC committee shall consist of the following club officers: Section Head, Treasurer, Secretary, Managers Representative & Child Welfare Officer all elected at an Annual General Meeting (AGM).<br/>
b) Each LCJFC officer & LCJFC committee member shall hold office from the date of appointment until the next AGM unless otherwise resolved at an Extraordinary General Meeting (“EGM”). One person may hold no more than two (2) positions of Club Officer at any time. The LCJFC committee shall be responsible for the management of all the affairs of the club. Decisions of the LCJFC committee shall be made by a simple majority of those attending the committee meeting. The Section Head of the LCJFC committee meeting shall have a casting vote in the event of a tie. Meetings of the LCJFC committee shall be chaired by the Secretary or in their absence, the Treasurer. The quorum for the transaction of business of the LCJFC committee shall be three (3).<br/>
c) Decisions of the LCJFC committee in meetings shall be entered into the minute book of LCJFC to be maintained by the Secretary.<br/>
d) Any member of the LCJFC committee may call a meeting of the LCJFC committee by giving not less than 7 days’ notice to all members of the LCJFC committee. The LCJFC committee shall hold not less than four (4) meetings per year.<br/>
e) An outgoing member of the LCJFC committee may be re-elected. Any vacancy on the Club Committee which arises between AGMs shall be filled by a member proposed by one and seconded by another one of the remaining Club Committee members and approved by a simple majority of the remaining Club Committee members.<br/>
f) Save as provided for in the Rules & Regulations of The FA & West Riding County Football Association and any applicable Competition, the Club Committee shall have the power to decide all questions and disputes arising in respects of any issue concerning the Club Rules.<br/>
g) The position of the Club Officer shall be vacated if such a person is subject to a decision of The FA that such person be suspended from holding office or from taking part in any football activity relating to the administration of management of a football club.
</p>

<h5>9) Annual & Extraordinary General Meetings</h5>
<p>
a) An AGM shall be held in each year to:
<ul>
<li>Receive a report of the activities of LCJFC over the previous year</li>
<li>Receive a report of LCJFC’s finances over the previous year</li>
<li>Elect the members of the LCJFC committee</li>
<li>Consider any other business.</li>
</ul>
b) Nominations for election of members as Club Officers or as members of the LCJFC committee shall be made in writing by the proposer and seconder, both of whom must be existing members of LCJFC, to the LCJFC Secretary not less than seven [7] days before the meeting. Notice of any resolution to be proposed at the AGM shall be given in writing to the Secretary not less than twenty one days before the meeting.<br/>
c) An EGM may be called at any time by the LCJFC committee & shall be called within 21 days of the receipt by the Club Secretary of a requisition in writing, signed by not less than five members, stating the purposes for which the Meeting is required & the resolutions proposed. Business at an EGM may be any business that may be transacted at an AGM.<br/>
d) The Secretary shall send to each member, at their last known address written notice of the date of a General Meeting (whether an AGM or EGM) together with the resolutions to be proposed at least 14 days before the meeting.<br/>
e) The quorum for a general meeting shall be four LCJFC committee members [4 out of 5 committee members].<br/>
f) The Section Head, or in their absence, a member selected by the LCJFC committee, shall take the chair. Each member present shall have one vote and resolutions shall be passed by a simple majority. In the event of an equality of votes the Chairperson of the Meeting shall have a casting vote.<br/>
g) The Club Secretary, or in their absence, a member of the LCJFC committee, shall enter minutes of general meetings into the Minute Book of LCJFC.
</p>

<h5>10) Club Teams</h5>
<p>
a) At its first meeting following each AGM, the LCJFC committee shall appoint a Club member to be responsible for each of the LCJFC football teams. The appointed members shall be responsible for managing the affairs of the team. The appointed members shall present to the LCJFC committee at its last meeting prior to an AGM a written report of the activities of the team.
</p>

<h5>11) Club Finances</h5>
<p>
a) A bank account shall be opened and maintained in the name of Leeds City Juniors (the LCJFC account). Designated account signatories shall be the Section Head, the Secretary & the Treasurer. Payments from the account shall be made by the Treasurer following email agreement from one other signatory. For sums over £3,000, authorisation is required by all 3 signatories. All monies payable to LCJFC shall be received by the Treasurer & deposited in the LCJFC account, or deposited into the account and the Treasurer notified.<br/>
b) The club property shall be applied only in furtherance of the objectives of LCJFC. All surplus income or profits are to be reinvested in the Club. If the club has any income, profits, gains or other funds which the Club committee considers not to be needed to meet the Club’s short term needs for providing facilities and participation then the Treasurer may deposit or invest those funds after taking appropriate professional advice. The Treasurer will report back to members at least annually on the performance of any such investments and review the Clubs investments in the light of its financial needs for providing facilities and participation.<br/>
c) No surplus income, profits or assets shall be distributed to third parties.<br/>
d) The LCJFC committee shall have power to authorise the payment of remuneration & expenses to any member of LCJFC (although the club shall not renumerate a member for playing) and to any other person or persons for services rendered to LCJFC.<br/>
e) The Club may provide sporting and related social activities, sporting equipment, coaching, courses, insurance cover, medical treatment, away match expenses, post-match refreshments and other ordinary benefits of Community Amateur Sports Clubs as provided for in the Corporation Tax Act 2010 and Finance Act 2010.<br/>
f) The Club may also in connection with the sports purposes of the Club:
<ul>
<li>Sell and supply food, drink and related sports clothing and equipment</li>
<li>Employ members (although not for playing) and remunerate them for providing goods and services, on fair terms set by the Club Committee without the person concerned being present</li>
<li>Pay for reasonable hospitality for visiting teams and guests and</li>
<li>Indemnify the Club Committee and members acting properly in the course of running the Club against any liability incurred in the proper running of the Club (but only to the extent of its assets).</li>
</ul>
g) The club shall keep accounting records for recording the fact and nature of all payments and receipts so as to disclose, with reasonable accuracy, at any time, the financial position including assets and liabilities of the Club. The Club must retain its accounting records for a minimum of six (6) years<br/>
h) LCJFC shall prepare an annual “Financial Statement” in such format as shall be available from the FA from time to time. The Financial Statement shall be verified by an independent, appropriately qualified accountant and shall be approved by members at a General Meeting. A copy of any Financial Statement shall, on demand, be forwarded to the FA.<br/>
i) LCJFC property, other than the LCJFC account, shall be vested in not less than two (2) & no more than four (4) custodians, one of whom shall be the Treasurer (“The Custodians”), who shall deal with LCJFC property as directed by decisions of the LCJFC committee & entry in the minute book shall be conclusive evidence of such a decision.<br/>
j) The Custodians shall be appointed by LCJFC in a General Meeting & shall hold office until death or resignation unless removed by a resolution passed at a General Meeting.<br/>
k) On their removal or resignation a Custodian shall execute a Conveyance in such form as is published by the FA from time to time to a newly elected custodian or the existing custodians as directed by the LCJFC committee. The club shall, on request, make a copy of any Conveyance available to the FA. On the death of a Custodian, any LCJFC property vested in them shall vest automatically in the surviving Custodians. If there is only one surviving Custodian, an EGM shall be convened as soon as possible to appoint another Custodian.<br/>
l) The Custodians shall be entitled to an indemnity out of LCJFC property for all expenses & other liabilities reasonably incurred by them in carrying out their duties.<br/>
m) Donations received by the Club are to be used in accordance with the wishes of the group, individual or organisation donating under the following guidelines:
<ul>
<li>General donations with no specific purpose are for the benefit of the Club and can be used as the Committee see fit</li>
<li>Donations made to a specific team with no specified use – these are to become the first source of funding for team expenses unless by exception of decision of the Committee, for example funding put aside towards a future tour would not be expected to be used to purchase kit</li>
<li>Donations for a specific use, for example the sponsorship of kit are to be used for the purpose specified.</li>
<li>The club will maintain a simple register of donations. Team managers or their representatives should advise the club secretary (on behalf of the committee) before fundraising applications are made.</li>
</ul>
</p>

<h5>12) Dissolution</h5>
<p>
a) A resolution to dissolve the club shall only be proposed at a general meeting & shall be carried by a majority of at least three-quarters of the members present.<br/>
b) The dissolution shall take effect from the date of the resolution & the members of the LCJFC committee shall be responsible for the winding up of the assets & liabilities of LCJFC.<br/>
c) Upon dissolution of the Club any assets remaining after the discharge of the debts and liabilities of the club shall be given or transferred to another registered community amateur sports club, a registered charity or the FA for use by them in related community sports.
</p>
</div> 
            </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
